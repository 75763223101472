body {
    overflow: overlay;
}

.required {
    color: red;
}

.otp-container > div {
    justify-content: space-evenly;
}

.otp-container > div > div > input {
    width: 3em !important;
    margin: 2px !important;
    padding: 8px !important;
}

table {
    table-layout: auto !important;
    margin-bottom: 0 !important;
    vertical-align: middle !important;
}

.react-bootstrap-table {
    background-color: #fff;
    background-clip: border-box;
    outline: 1px solid #eef2f7;
    border-radius: 0.25rem;
    white-space: nowrap;
    overflow: auto;
    overflow-y: hidden;
    min-height: 164px;
}

.react-bootstrap-table-pagination {
    margin-top: 1rem;
}

.pagination {
    float: right;
}


/* width */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #bebebe;
    border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #888;
}

.react-tel-input > input {
    width: 100% !important;
    padding: 8px 14px 8px 60px !important;
    border: 1px solid #dee2e6 !important
}

.react-tel-input > input:focus {
    border: 1px solid #c8cbcf !important
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-not-allowed {
    cursor: not-allowed;
}

#recaptcha-container > div > div {
    width: auto !important;
}

/* hide arrows from input type number */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}


.ml-2 {
    margin-left: 0.75rem;
}

.mr-2 {
    margin-right: 0.75rem;
}

.txt-right {
    text-align: right;
}

.float-right {
    float: right;
}

.align-self-center {
    align-self: center;
}

.logo-image-upload-view {
    object-fit: contain;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    padding: 0.45rem;
    min-width: 100px;
    max-width: 188px;
    height: 100px;
}

.remove-padding {
    padding-left: unset !important;
}

.height-fit-content {
    height: fit-content;
}

.form-select:disabled {
    border: 1px solid var(--ct-input-border-color);
}

.phone-input input {
    padding: 6px 14px 6px 60px !important
}

.btn {
    box-shadow: unset !important;
}

#shop_open_close {
    width: 70px !important;
}

/* #shop_open_close::after {
    left: 49px;
}

#shop_open_close::before {
    left: 4px;
} */

input[data-switch]:checked+label::after {
    left: 50px;
}


.page-link:focus {
    box-shadow: none;
}

.shop-logo {
    width: 50px;
    height: 50px;
    border-radius: 8px;
    object-fit: contain;
}

.fill-height {
    height: 100%;
}

.min-width {
    min-width: fit-content;
}

.product-image-table {
    object-fit: contain;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    padding: 4px;
    width: 60px;
    height: 60px;
}

.big-dots-vertical {
    font-size: 22px;
}

.shop-logo-qr-builder {
    width: 200px;
    height: 50px;
    object-fit: contain;
}

.logo-qr-builder {
    width: auto;
    height: 48px;
}

.qr-builder-container {
    width: 400px;
    height: 500px;
}

.qr-download-button {
    width: 400px;
}

.qr-code {
    width: 160px;
    height: 160px;
}

.qr-code-home {
    width: 100px;
    height: 100px;
}

.width-fit-content {
    width: fit-content;
}

.shop-title {
    line-height: 70px;
    float: left;
    margin: auto;
}

.row-expansion-style {
    background-color: #FAFBFE !important;
}

.shift-table {
    margin-left: 24px;
}

.product-image {
    width: 100px;
    height: 100px;
    object-fit: contain;
}

.expand-product-icon {
    cursor: pointer;
    font-size: 20px;
}

.logo-lg > img {
    max-width: 200px;
    max-height: 46px;
    object-fit: scale-down;
}

.logo-sm > img {
    max-width: 60px;
    max-height: 46px;
    object-fit: scale-down;
}

.object-fit-scale-down {
    object-fit: scale-down;
}

.pending-order-container {
    min-height: 50px !important;
    /* background-color: red; */
    width: 100%;
    top: 70px;
}

.address-container p {
    margin: 0;
}

.order-summary-container p {
    margin: 0;
}

.pending-order-card {
    min-width: 200px !important;
    margin-top: 12px;
    margin-bottom: 12px;
    margin-right: 12px;
}

.pending-order-count {
    align-self: start;
    margin-left: -8px;
}

.pending-order-action-container {
    display: grid;
}

.banner-image-upload-view {
    object-fit: cover;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    padding: 0.45rem;
    width: 100%;
    height: 166px;
}

.remove-white-space {
    white-space: normal !important;
}

.text-link {
    color: blue;
    text-decoration: underline;
}

.slider-container {
    height: 300px;
}

.carousel-inner {
    height: 300px;
    border-radius: 4px;
}

.carousel-item {
    height: 300px;
}

.carousel-item img {
    height: 300px;
    border-radius: 4px;
    object-fit: cover;
}


.BrainhubCarousel__arrows {
    border-radius: 5px;
}

.BrainhubCarousel__thumbnail img {
    height: 80px;
    width: 80px;
    object-fit: contain;
}

.BrainhubCarousel__thumbnail {
    border-radius: 5px !important;
    margin: 2px !important;
    padding: 0 !important;
}

.BrainhubCarouselItem img {
    width: inherit;
}

.product-slider-image {
    padding: 8px;
}

.magnified-image-view {
    position: absolute !important;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}


/*! Flickity v2.1.2
https://flickity.metafizzy.co
---------------------------------------------- */

.flickity-enabled {
    position: relative;
}

.flickity-enabled:focus {
    outline: none;
}

.flickity-viewport {
    overflow: hidden;
    position: relative;
    height: 100%;
}

.flickity-slider {
    position: absolute;
    width: 100%;
    height: 100%;
}

/* draggable */

.flickity-enabled.is-draggable {
    -webkit-tap-highlight-color: transparent;
    tap-highlight-color: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
    cursor: move;
    cursor: -webkit-grab;
    cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
    cursor: -webkit-grabbing;
    cursor: grabbing;
}

/* ---- flickity-button ---- */

.flickity-button {
    position: absolute;
    background: hsla(0, 0%, 100%, 0.75);
    border: none;
    color: #333;
}

.flickity-button:hover {
    background: white;
    cursor: pointer;
}

.flickity-button:focus {
    outline: none;
    box-shadow: 0 0 0 5px #19f;
}

.flickity-button:active {
    opacity: 0.6;
}

.flickity-button:disabled {
    opacity: 0.3;
    cursor: auto;
    /* prevent disabled button from capturing pointer up event. #716 */
    pointer-events: none;
}

.flickity-button-icon {
    fill: #333;
}


.flickity-page-dots {
    /* position: absolute; */
    width: 100%;
    padding: 0;
    margin-top: 8px;
    margin-bottom: -8px;
    list-style: none;
    text-align: center;
    line-height: 1;
}

.flickity-rtl .flickity-page-dots {
    direction: rtl;
}

.flickity-page-dots .dot {
    display: inline-block;
    width: 4px;
    height: 4px;
    margin: 8px;
    background: #333;
    border-radius: 50%;
    opacity: 0.25;
    cursor: pointer;
}

.flickity-page-dots .dot.is-selected {
    opacity: 1;
}


.swiper-button-prev,
.swiper-button-next {
    color: var(--custom-primary) !important;
}

.swiper-pagination-bullet-active {
    background-color: var(--custom-secondary) !important;
}

.mySwiper {
    border-radius: 0.25rem;
    height: 415px;
    width: 415px;
}

.swiper-slide {
    height: 415px;
    width: 415px;
}

.swiper-slide img {
    height: 415px;
    width: 415px;
    object-fit: contain;
}

.top-toggle-buttons {
    padding: 2px 10px 0;
    font-size: 24px;
}

.qr-text-height {
    height: 24px !important;
    margin: 0 !important;
    padding: 0 !important;
}